import axios from "axios";
import ElementUI from "element-ui";
 import router from "../router"; 
/*import store from '../store/index';
// 接口
axios({ 
    url: "url",
    method: 'post',
    data: {}
});
// 跳转
router.push({
    path: "/login",
    errorMsg: '8'
});
//提示
ElementUI.Message({
    message: response.data.msg,
    type: "warning",
}); */
const service = axios.create({
    baseURL: "http://guanli.konne.cn/" 
});
service.interceptors.request.use(
    function(config) {
        // 一般在这个位置判断token是否存在
        /* const token = 'Bearer '+ window.localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = token;
        } */
        return config;
    },
    function(error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);
var number = 1;
//响应拦截
service.interceptors.response.use(
    function(response) {
        // 处理响应数据
        if (response.data.code === 200) {
            return Promise.resolve(response)
        }else if(response.data.code === 201){
            return Promise.resolve(response)
        }
        else if(response.data.code === 204){ //暂无数据转为正常返回
            response.data.code = 200
            return Promise.resolve(response)
        }
         else if(response.data.code === 400){
            window.localStorage.clear()
            router.push({
                path: "/login",
            })
            if(number == 1){ //登陆过期只提示一次
               ElementUI.Message({
                    message: response.data.msg,
                    type: "warning",
                }) 
            }
            number = 2
            setTimeout(()=>{
                number = 1
            },2000)
        }else if(response.data.type){ //文件
            return
        }
        else if(response.data.code === 500){
            return Promise.resolve(response)
        }
        else{
            ElementUI.Message({
                message: response.data.msg,
                type: "warning",
            })
            return Promise.resolve(response)
        }
    
    },
    function(error) {
        // 处理响应失败
        return Promise.reject(error);
    }
);
export default service;