import request from '../wxRequest.js';
//获取unionid
function getUid(data) {
    return request({
        url:"konne_auth/tpw/wechatIdInfo"+`?state=${data.state}`,
        method: 'get',
    })
}
function wcCallBack(data) {
    return request({
        url:"konne_auth/tpw/wcCallBack"+`?code=${data.code}&state=${data.state}`,
        method: 'get',
    })
}
export default{
    getUid,
    wcCallBack
}